const appConfig={
    apiPrefix: `${process.env.REACT_APP_API_URL}/api/v1`,
    authenticatedVendorEntryPath: '/vendor/dashboard',
    authenticatedAdminEntryPath: '/admin/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

// const appConfig={
//     apiPrefix: '/api',
//     authenticatedEntryPath: '/vendor/dashboard',
//     unAuthenticatedEntryPath: '/sign-in',
//     tourPath: '/',
//     locale: 'en',
//     enableMock: true,
// }

export default appConfig
