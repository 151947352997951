import { createServer } from 'miragejs'
import appConfig from 'configs/app.config'

import { signInUserData } from './data/authData'

import {
    productsData,
    ordersData,
    orderDetailsData,
    salesDashboardData,
} from './data/salesData'

// import {productsData,ordersData,customersData,vendorsData,salesDashboardData,servicesData} from './data/mockData'

import { authFakeApi, salesFakeApi, myFakeApi } from './fakeApi'

const { apiPrefix } = appConfig

export default function mockServer({ environment = 'test' }) {
    return createServer({
        environment,
        seeds(server) {
            server.db.loadData({
                // customersData,
                // vendorsData,
                // servicesData,
                signInUserData,
                productsData,
                ordersData,
                orderDetailsData,
                salesDashboardData,
            })
        },
        routes() {
            this.urlPrefix = ''
            this.namespace = ''
            this.passthrough((request) => {
                let isExternal = request.url.startsWith('http')
                return isExternal
            })
            this.passthrough()

            authFakeApi(this, apiPrefix)
            salesFakeApi(this, apiPrefix)
            myFakeApi(this, apiPrefix)
        },
    })
}
