import {ADMIN,VENDOR} from '../constants/roles.constant'
import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: `/login`,
        method: 'post',
        data,
    })
}


// export async function apiAdminSignIn(data) {
//     return ApiService.fetchData({
//         url: `/${ADMIN}/login`,
//         method: 'post',
//         data,
//     })
// }

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: `/sign-up`,
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/logout',
        method: 'get',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/auth/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/auth/reset-password',
        method: 'post',
        data,
    })
}
